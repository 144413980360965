import { render, staticRenderFns } from "./ProductVarieties.vue?vue&type=template&id=d0468c42&scoped=true&"
import script from "./ProductVarieties.vue?vue&type=script&lang=js&"
export * from "./ProductVarieties.vue?vue&type=script&lang=js&"
import style0 from "@@/core/components/product/ProductVarieties/ProductVarietiesStyle.css?vue&type=style&index=0&id=d0468c42&prod&scoped=true&lang=css&"
import style1 from "@@/core/components/product/ProductVarieties/ProductVarietiesStylePublic.css?vue&type=style&index=1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0468c42",
  null
  
)

export default component.exports